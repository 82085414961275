.checkout\:main {
  container-type: inline-size; // For page layout grid-template-areas below

  @media screen and (max-width: 890px) {
    &:has(.elm-datepicker.--open) {
      // Anything other than `normal` breaks datepicker's `position:fixed` (mobile)
      // See https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block#identifying_the_containing_block
      container-type: normal;
    }
  }

  [hidden] {
    display: none;
  }

  .checkout\:page-layout {
    --layout-row-gap: var(--space);
    --layout-col-gap: var(--space-lg);

    display: grid;
    row-gap: var(--layout-row-gap);
    column-gap: var(--layout-col-gap);

    &.page-layout\:voucher-booking:has(.elm-datepicker) {
      z-index: 1; // Ensure fixed picker (on mobile) is above footer
    }

    > * {
      max-width: none;
      align-self: start;
    }

    > .ui-component-form {
      --form-row-gap: var(--layout-row-gap);
    }

    &.page-layout\:booking,
    &.page-layout\:voucher-booking {
      .checkout\:loyalty-notice,
      .checkout\:details-summary {
        order: -1;
      }
    }

    &.page-layout\:booking-payment {
      .checkout\:payment-options {
        order: 1;
      }
    }

    &.page-layout\:booking-payment,
    &.page-layout\:voucher-booking-payment {
      .checkout\:payment-options {
        & > div:first-child {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          column-gap: var(--space);

          p {
            margin: 0;

            i.fa-lock {
              margin-right: var(--space-3xs);
            }
          }
        }
      }
    }

    .trustpilot-widget {
      container-type: inline-size;

      > iframe {
        @container (width <= 419px) {
          height: 40px !important;
        }
      }
    }

    .checkout\:payment-summary-and-trustpilot {
      display: contents;
    }

    .ui-component-box.variant\:upsells {
      container-type: inline-size; // For upsells carousel

      .ui-component-card {
        flex-basis: 360px;
        border: 2px solid transparent;
        box-sizing: border-box;

        &:has(input:checked) {
          border-color: $sb-green;
          box-shadow: none;
        }

        [data-img] {
          height: 140px;
        }
      }
    }

    &.page-layout\:voucher-booking-payment {
      .checkout\:sidebar-summary {
        margin-inline: auto;
        max-width: 37.5rem;

        .ui-component-voucher {
          max-width: clamp(180px, 75%, 280px);
          min-width: auto;
          margin-top: calc(-1 * var(--space-3xl));
          margin-bottom: var(--space);
          font-size: 75%;

          .utils\:wave {
            font-size: 325%;

            &::after {
              max-height: 50%;
            }
          }
        }

        p:last-child {
          margin-bottom: 0;
        }

        table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;

          tbody {
            th, td {
              padding-bottom: var(--space-3xs);
            }

            tr:last-child {
              th, td {
                padding-bottom: var(--space-sm);
              }
            }
          }

          th + td {
            text-align: right;
            vertical-align: top;
          }

          tbody th {
            width: 75%;
            font-weight: 400;
          }

          tfoot {
            tr:first-child, tr:last-child {
              border-top: $ui-border;
            }
          }
        }
      }
    }

    @container (width >= 890px) {
      // main section and sidebar
      grid-template-columns: auto 375px;
      grid-template-rows: min-content min-content 1fr;

      &.page-layout\:old-booking-payment {
        grid-template-rows: min-content min-content min-content 1fr;
        grid-template-areas:
          "header details-summary"
          "loyalty details-summary"
          "payment-options details-summary"
          "payment-options payment-summary-and-trustpilot";

        > header {
          grid-area: header;
        }

        .checkout\:payment-options {
          grid-area: payment-options;
        }
      }

      &.page-layout\:booking-payment {
        grid-template-rows: min-content min-content min-content 1fr;
        grid-template-areas:
          "loyalty details-summary"
          "payment-options details-summary"
          "payment-options details-summary"
          "payment-options payment-summary-and-trustpilot";

        .checkout\:payment-options {
          grid-area: payment-options;
        }
      }

      &.page-layout\:booking-payment,
      &.page-layout\:voucher-booking-payment {
        .checkout\:payment-options {
          details[name="payment-options-tabs"] {
            section .ui-component-button.variant\:pill {
              font-weight: 400;
              font-size: 1.065rem;
            }
          }
        }
      }

      &.page-layout\:voucher-booking-payment {
        .checkout\:sidebar-summary {
          order: 2;
        }
      }

      &.page-layout\:booking {
        grid-template-areas:
          "loyalty details-summary"
          "form details-summary"
          "form payment-summary-and-trustpilot"
          "payment payment-summary-and-trustpilot";
      }

      &.page-layout\:voucher-booking {
        grid-template-areas:
          "form details-summary"
          "form payment-summary-and-trustpilot"
          "payment payment-summary-and-trustpilot";
      }

      .checkout\:loyalty-notice {
        grid-area: loyalty;
      }

      > .ui-component-form {
        grid-area: form;
      }

      .checkout\:details-summary {
        order: unset;
        grid-area: details-summary;
      }

      .checkout\:payment-summary-and-trustpilot {
        display: initial;
        position: sticky;
        top: var(--layout-row-gap);
        grid-area: payment-summary-and-trustpilot;
        align-self: start;

        > .ui-component-box {
          overflow-y: auto; // Ensure summary scrollable on small viewports
          max-height: calc(100vh - 2 * var(--layout-row-gap));
          margin-bottom: var(--layout-row-gap);

          &:has(.ui-component-loader:not([hidden])) {
            // For absolute `.ui-component-loader`
            position: relative;
            overflow: hidden;
          }
        }
      }

      > section:last-child {
        grid-area: payment;
      }
    }
  }

  .checkout\:cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: var(--space-xs);
    justify-items: center;

    @media screen and (max-width: 600px) {
      grid-template-rows: repeat(auto-fit, minmax(240px, 1fr));
    }

    + button {
      width: auto;
      margin: 0 auto;
    }
  }

  .checkout\:delivery-options,
  .checkout\:upsells {
    a {
      text-decoration: underline;
      text-align: center;
    }

    .ui-component-card {
      border: 2px solid transparent;
      cursor: pointer;
      user-select: none;

      &.spacer {
        box-shadow: none;
      }

      header {
        line-height: 1.5;
      }

      &:has(input:checked) {
        border-color: $sb-green;
      }

      footer {
        display: flex;
        justify-content: space-between;
        gap: var(--space-3xs);
      }

      input {
        display: none;
      }
    }
  }

  .checkout\:delivery-options {
    .ui-component-card {
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      footer {
        flex-direction: row;
        align-items: center;
        margin: 0;
      }
    }
  }

  .checkout\:upsells {
    .ui-component-card {
      footer {
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: 600px) {
        .ui-component-button {
          width: fit-content;
        }
      }
    }
  }

  .checkout\:details-summary {
    overflow: unset;
    gap: var(--space-sm);

    > section:first-of-type { // image and venue name
      display: flex;
      gap: $space-xs;

      > :first-child {
        container-type: inline-size;
        flex-basis: 112px;

        img {
          border-radius: 4px;
          object-fit: cover;
          height: 100%;
          max-height: 100cqw;
        }
      }

      > :last-child {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.25;

        > :last-child span {
          font-size: 12px;
        }
      }
    }

    > section:nth-of-type(2) { // check-in and check-out info
      display: flex;
      gap: $space-xs;

      > div {
        font-size: 14px;
        > :nth-child(2) {
          font-size: 16px;
          font-weight: 700;
        }
      }

      > :first-child {
        flex: 1;
      }

      > :not(:first-child) {
        width: 50%;
        padding-left: 12px;
        border-left: $ui-border;
      }
    }

    > section:nth-of-type(3) { // package details
      details > section {
        display: flex;
        flex-direction: column;
        gap: $space-xs;
      }

      details > section > section {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        gap: var(--space-3xs);

        > :first-child { // section heading
          font-size: 14px;
          font-weight: 400;
        }

        .special-offers {
          font-size: 12px;
          display: inline-block;
          line-height: 1.2;
          color: $sb-purple-500;
          border-radius: 3px;
          padding: 8px;
          background-color: $sb-purple-100;
        }
      }
    }
  }

  .checkout\:payment-summary-and-trustpilot {
    table {
      width: 100%;
      border-collapse: collapse;

      th {
        font-weight: normal;
      }

      td {
        text-align: right;
        vertical-align: top;
      }
    }

    tbody {
      th,td {
        padding-bottom: var(--space-xs);
      }

      th {
        line-height: 22px;
        width: 75%;

        div:first-letter {
          text-transform: capitalize;
        }
      }

      &.promo-codes {
        border-top: 1px solid $grey-200;

        th, td {
          height: 60px;
          padding-top: var(--space-xs);
        }
      }
    }

    tfoot {
      font-size: 21px;

      th, td {
        font-weight: 600;
      }
    }

    .ui-component-notice {
      margin-top: var(--space);
    }

    .ui-component-klarna-widget {
      margin-top: var(--space-sm);

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .discount-pill {
      display: inline-flex;
      align-items: center;
      gap: var(--space-2xs);
      padding: var(--space-3xs);
      white-space: nowrap;
      letter-spacing: 1px;
      line-height: 1rem;
      border-radius: 4px;
      text-decoration: none;
      font-weight: 400;
      font-size: $base-font;
      background-color: $sb-green-100;
      color: $sb-green;
      text-transform: uppercase;
    }
  }

  &.checkout\:voucher {
    h2.required {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      &::before {
        content: '*';
        flex: 1;
        color: $sb-red;
        font-size: calc(var(--base-font) / var(--font-scale));
        font-weight: 400;
        text-align: end;
        order: 1;
      }

      &::after {
        content: 'Required';
        font-size: calc(var(--base-font) / var(--font-scale));
        font-weight: 400;
        color: $grey-400;
        margin-left: calc(var(--space-3xs) / 2);
        order: 2;
      }
    }

    .checkout\:evoucher-delivery, .checkout\:physical-delivery, .checkout\:gift-message {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--space-sm);
    }

    .checkout\:delivery-options {
      gap: var(--space);

      & > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--space);
      }
    }

    .checkout\:evoucher-delivery {
      & > * {
        width: 100%;
      }

      .ui-component-radio-group {
        width: 100%;
        > * {
          flex: 1;
        }

        > :last-child {
          label {
            position: relative;
          }

          i.fa-calendar { // calendar icon for deselected state of "Specific date"
            color: $sb-green;
            margin-right: var(--space-2xs); // same margin as radio input
            margin-left: 1px; // better alignment with radio input
          }

          &:has(input:checked) {
            i.fa-calendar {
              display: none;
            }
          }

          input[type="radio"] {
            &:not(checked) + span::before {
              display: none;
            }

            &:focus + span::before,
            &:checked + span::before {
              display: flex;
            }
          }
        }
      }
    }

    .checkout\:details-summary {
      --image-height: 66px;
      gap: var(--space-sm);

      section > div:first-child {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .ui-component-disclosure-item > section {
        display: flex;
        flex-direction: column;
        gap: var(--space-sm);
      }

      p {
        margin: 0;
      }

      div:last-child {
        & > p:first-child {
          font-weight: 400;
        }

        & > p:nth-of-type(2n) {
          font-size: 1rem;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .details-summary\:monetary {
        & > div:last-child {
          gap: 0.25rem;
          justify-content: center;
        }

        .ui-component-voucher > div:first-child {
          padding-bottom: var(--image-height);

          & > div {
            align-items: center;
            background-image: -webkit-linear-gradient(right top, #65c3c2 20%, #5abfbe);
          }
        }
      }

      img {
        border-radius: 0.25rem;
        object-fit: cover;
        max-height: var(--image-height);
      }

      .details-summary\:package {
        font-weight: 700;

        .reviews__venue-rating {
          font-size: 0.75rem;
        }

        div:last-child {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          > div:last-child {
            display: block;
            flex-direction: row;
            align-items: center;
          }

          & > p:first-child {
            line-height: 20px;
            font-size: 1rem;
            font-weight: 700;
          }
        }

      }
    }

    .checkout\:physical-delivery {
      & > :not(aside) {
        width: 100%;
      }

      .ui-component-radio-group {
        margin-bottom: 0;
      }

      .address-results {
        width: 100%;
        padding: $space-xxs;
        background-color: #fff;
        border: $ui-border;
        border-radius: $ui-border-radius;
        box-shadow: $ui-box-shadow;
        display: flex;
        flex-direction: column;
        gap: var(--space-xs);
        margin-top: var(--space-xs);
        margin-bottom: 0;
        max-height: sb-rem(360px);
        overflow: auto;

        legend {
          margin-bottom: var(--space-xs);
        }
      }
    }

    .checkout\:gift-message {
      section:last-child {
        @media screen and (max-width: 600px) {
          grid-template-areas:
            "first"
            "last";
          grid-template-columns: 1fr;
          gap: var(--space-xs);
        }

        section:last-child {
          display: flex;
          gap: var(--space-xs);
        }
      }
    }
  }

  &.checkout\:complete {
    padding-top: var(--space-2xl);
    background-color: $grey-100;

    .checkout\:complete-layout {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--space-lg);
    }

    .checkout\:complete-header {
      & > header {
        margin-top: var(--space-sm);
      }

      h2 {
        margin: 0;
      }

      aside {
        font-size: var(--font-lg);
        width: fit-content;
        margin: var(--space) auto 0;
      }
    }

    .checkout\:complete-main {
      display: flex;
      flex-direction: column;
      row-gap: var(--space-lg);

      & > section {
        width: 100%;
        padding: var(--space-lg);
        background-color: #fff;
        border-radius: $ui-border-radius;
      }

      @media screen and (max-width: 600px) {
        & > section {
          padding: var(--space);
        }
      }
    }

    .checkout\:complete-booking-ref {
      h3 {
        span {
          font-weight: 400;
          margin-left: var(--space-2xs);
        }

        border-bottom: 1px solid $grey-200;
        padding-bottom: var(--space-sm);
        margin: 0;
      }

      p {
        margin: var(--space-sm) 0;
      }
    }

    .checkout\:complete-location-details {
      i {
        margin-right: var(--space-3xs);
      }
    }

    .checkout\:complete-check-in {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      & > div:first-child {
        border-right: $ui-border;
      }

      & > div:last-child {
        justify-self: end;
        text-align: end;
      }

      & > div p {
        font-size: var(--font-lg);
        margin: 0;
      }

      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;

        & > div:first-child {
          border-bottom: $ui-border;
          border-right: none;
          padding-bottom: var(--space-sm);
        }

        & > div:last-child {
          padding-top: var(--space-sm);
          justify-self: start;
          text-align: start;
        }
      }
    }

    .checkout\:complete-create-account {
      .checkout\:complete-create-account-benefits {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;

        i {
          color: $sb-green;
          margin-bottom: var(--space-3xs);
        }

        p {
          max-width: 260px;
        }
      }

      form {
        margin-bottom: var(--space-sm);
      }
    }

    .checkout\:complete-next {
      ol {
        li {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: var(--space-3xs);
          padding-bottom: var(--space-sm);
          padding-left: var(--space);

          // The bullet
          &::after {
            content: "";
            position: absolute;
            top: 4px;
            left: -1px;
            width: 15px;
            height: 15px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%232b8281' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
          }

          > :first-child {
            font-weight: 700;
          }
        }
      }
    }

    .checkout\:complete-trustpilot {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space);
      padding: var(--space);

      > * {
        margin-bottom: 0;
      }

      > p:last-of-type {
        max-width: 850px;
      }
    }

    .checkout\:complete-delivered-to {
      display: flex;
      justify-content: space-between;
      gap: var(--space);

      &:has(.gift-message-preview) > div:first-child {
        max-width: 30%;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;

        &:has(.gift-message-preview) > div:first-child {
          max-width: 100%;
        }
      }

      p:first-child {
        margin-bottom: var(--space-xs);
      }

      p:last-child {
        margin-bottom: 0;
      }

      .gift-message-preview {
        flex: 1;
        max-width: 552px;
      }
    }
  }
}

.checkout\:error {
  .checkout\:hero {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $space;
    max-width: 200px;
    width: 100%;
  }

  > .ui-component-layout {
    > div:first-child {
      padding: $space-xxl $space;

      > div {
        max-width: 600px;
        margin: 0 auto;
      }
    }

    > div:last-child:not(:first-child) {
      align-self: stretch;
      padding-top: $space-3xl;
      padding-bottom: $space-3xl;
      background-color: $grey-100;

      > div {
        display: flex;
        flex-direction: column;
        gap: $space-xl;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 0 $space;
        max-width: 480px;
        min-height: 100%;
        text-align: center;
      }

      h2 {
        margin-bottom: 0;
      }
    }

    @include desktop {
      > div:first-child {
        padding-left: $space-3xl;
        padding-right: $space-xxl;
      }

      > div:last-child:not(:first-child) {
        max-width: 40%;
        background-image: url(../images/bg-account.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;

        > div {
          padding-left: $space-3xl;
        }
      }
    }
  }

  .ui-component-carousel {
    margin-bottom: $space-xl;

    ul {
      ~ button {
        display: none !important; // hide prev/next buttons
      }

      li i {
        line-height: 1.5;
      }
    }

    .carousel-indicators {
      display: flex !important; // don't hide on mobile
      gap: $space;
      top: calc(100% + $space-xs);

      @include tablet-landscape-up {
        gap: 0;
      }
    }
  }
}

.gift-message-preview {
  --message-font-size: var(--font-lg);
  --theme-image-height: 50px;

  display: grid;
  grid-template-columns: 75px 1fr 75px;
  gap: 10px; // TODO: Replace with var(--spacing-2xs) in SB-7808
  padding: var(--space);
  background-color: $grey-100;

  h3 {
    grid-column: 1 / -1;
    align-content: center;
    margin: 0 auto;
    min-height: calc(var(--message-font-size) * var(--base-line-height));
    font-size: var(--message-font-size);
    font-weight: 700;
    text-align: center;
  }

  span, h3 {
    line-height: var(--base-line-height);
  }

  span {
    grid-column: 2;
    margin: 0 auto;
    min-height: var(--theme-image-height);
    font-size: var(--base-font);
    text-align: center;

    &:last-of-type {
      align-content: end;
    }
  }

  img {
    height: var(--theme-image-height);

    &:first-of-type {
      grid-column: 3;
      margin-left: auto;
    }

    &:last-of-type {
      grid-column: 1;
      margin-top: auto;
    }
  }
}
